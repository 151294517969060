/* ==========================================================================
   BODY
   ========================================================================== */

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;

  overflow-x: hidden;
  touch-action: pan-x pan-y;

  main {
    flex: 1 0 auto;
  }

  footer {
    flex-shrink: 0;
  }
}
