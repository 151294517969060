/* ==========================================================================
   #LINKS
   ========================================================================== */

a {
  @include anim($prop: color);

  color: map-get($brand-colors, 'black', '800');
  text-decoration: underline;

  &:hover {
    color: map-get($brand-colors, 'black', '400');
  }

  &:focus-visible,
  &.is-focused {
    &:not(.c-btn) {
      outline: none;
      box-shadow: -4px -4px 0 0 hsla(13, 74%, 70%, 1),
        4px 4px 0 0 hsla(13, 74%, 70%, 1), -4px 4px 0 0 hsla(13, 74%, 70%, 1),
        4px -4px 0 0 hsla(13, 74%, 70%, 1), 0 1px 2px 0 hsla(0, 0%, 0%, 0.06),
        0 1px 3px 0 hsla(0, 0%, 0%, 0.1);
    }
  }
}
