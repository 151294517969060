.c-form {
  margin-top: 2rem;

  @include bp($large-bp) {
    margin-top: 4rem;
  }
}

.c-form__response {
  padding: 1rem;
  border-radius: 0.25rem;
  border: 0.125rem solid map-get($brand-colors, 'error', '900');
  background-color: map-get($brand-colors, 'error', '900');
  color: map-get($brand-colors, 'error', '500');

  @include text-token('sans', 'copy', 'lg');

  margin-bottom: 1.5rem;
}

.c-form__response--success {
  border-color: map-get($brand-colors, 'success', '500');
  background-color: map-get($brand-colors, 'success', '100');
  color: map-get($brand-colors, 'success', '500');
}
