/* ==========================================================================
   LIGHTBOX
   ========================================================================== */

.u-lightbox__trigger {
  margin: 0;
  padding: 0.25rem 0.5rem;

  @include bp($large-bp) {
    padding: 0.25rem 0.25rem 0.25rem 0.75rem;
  }
}

.u-lightbox__trigger--above {
  position: absolute;
  z-index: 1;
  bottom: 0.5rem;
  right: 0.5rem;
  background-color: map-get($brand-colors, 'black', '900-50');

  @include bp($large-bp) {
    bottom: 1rem;
    right: 1rem;
  }
}
