/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();

  margin-right: auto;
  margin-left: auto;
  max-width: map-get($breakpoints, xlarge);
  padding: 0 3rem;
  width: 100%;

  &,
  &.o-wrapper--full {
    @include bp-between($large-bp, $wide-bp) {
      padding: {
        left: 3rem;
        right: 3rem;
      }
    }
  }

  @include bp($wide-bp) {
    padding: 0;
  }
}

.o-wrapper--relative {
  position: relative;
  height: 100%;
}

.o-wrapper--full {
  width: 100%;
}
