/* ==========================================================================
   #LAYOUT
   ========================================================================== */

/* Simple flexbox layout system */

.columns {
  &.is-flex-direction-column-reverse-mobile {
    @include bp-down($large-bp) {
      flex-direction: column-reverse !important;
    }
  }

  @include bp($large-bp) {
    &.is-flex-justify-content-end-desktop {
      justify-content: flex-end;
    }
  }
}

.o-cols {
  @extend .columns;
}

.o-col {
  @extend .column;
}
