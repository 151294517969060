.c-progress {
  position: fixed;
  top: 10px;
  left: 10px;
  width: calc(100vw - 20px);
  height: 5px;
  z-index: 999;
  background-color: rgba(#537d6c, 0.25);
}

.c-progress__bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(0, 1);
  transform-origin: 0 0 0;
  background-color: #537d6c;

  will-change: transform;
  transition: $global-transition;
}
