/* ==========================================================================
   TOOLTIP
   ========================================================================== */

.u-tooltip__content {
  display: none;
  max-width: 60ch;
}

.u-tooltip__icon-wrap {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  background-color: map-get($brand-colors, 'green', '200');
  border-radius: 50%;
  margin-left: 0.5rem;

  .is-disabled &,
  [disabled] & {
    background-color: map-get($brand-colors, 'black', '200');
  }

  .c-btn--pill-small & {
    width: 1rem;
    height: 1rem;
    margin-left: 0.375rem;
  }

  @include bp($large-bp) {
    .c-btn--pill-small & {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}

.u-tooltip__icon {
  position: relative;
  width: 0.375rem;
  height: 0.75rem;
  fill: map-get($brand-colors, 'green', '800');

  .is-disabled &,
  [disabled] & {
    fill: map-get($brand-colors, 'white', '900');
  }

  .c-btn--pill-small & {
    width: 0.25rem;
    height: 0.5rem;
  }

  @include bp($large-bp) {
    width: 0.5rem;
    height: 1rem;

    .c-btn--pill-small & {
      width: 0.375rem;
      height: 0.75rem;
    }
  }
}

.tippy-box[data-theme~='vowo'] {
  padding: 0.625rem;
  color: map-get($brand-colors, 'green', '900');
  background-color: map-get($brand-colors, 'green', '200');

  &[data-placement^='top'] > .tippy-arrow::before {
    border-top-color: map-get($brand-colors, 'green', '200');
  }

  &[data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: map-get($brand-colors, 'green', '200');
  }

  &[data-placement^='left'] > .tippy-arrow::before {
    border-left-color: map-get($brand-colors, 'green', '200');
  }

  &[data-placement^='right'] > .tippy-arrow::before {
    border-right-color: map-get($brand-colors, 'green', '200');
  }
}

.tippy-box[data-theme~='vowo-dark'] {
  padding: 0.625rem;
  color: map-get($brand-colors, 'white', '900');
  background-color: map-get($brand-colors, 'green-black', '900');

  &[data-placement^='top'] > .tippy-arrow::before {
    border-top-color: map-get($brand-colors, 'green-black', '900');
  }

  &[data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: map-get($brand-colors, 'green-black', '900');
  }

  &[data-placement^='left'] > .tippy-arrow::before {
    border-left-color: map-get($brand-colors, 'green-black', '900');
  }

  &[data-placement^='right'] > .tippy-arrow::before {
    border-right-color: map-get($brand-colors, 'green-black', '900');
  }
}
