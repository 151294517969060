/* ==========================================================================
   CARD
   * [ 1 ] Take column space into account when increasing the space
   ========================================================================== */

.c-card {
  background-color: map-get($brand-colors, 'white', '900');
  box-shadow: -22px 20px 60px rgba(0, 0, 0, 0.05);
  border-radius: 0.25rem;
  padding: 1.25rem 1.25rem 1.5rem;
  transition: $global-transition;

  @include bp($large-bp) {
    padding: 2rem 2rem 2.5rem;
  }
}

/* Style variants
   ========================================================================== */

.c-card--default {
  padding: 1rem 1.25rem 1.5rem;
  display: block;
  box-shadow: none;
  height: 100%;
  border: 1px solid map-get($brand-colors, 'black', '200');

  @include bp($large-bp) {
    padding: 1.5rem 1.5rem 2rem;
  }
}

.c-card--person {
  box-shadow: none;
  min-width: 16.375rem;
  padding: 0;
  border-radius: 0.25rem;
  overflow: hidden;
  border: 1px solid map-get($brand-colors, 'black', '200');
  max-width: 18.75rem;

  @include bp($wide-bp) {
    max-width: 22rem;
  }

  &.c-card--landscape {
    display: flex;
  }

  .c-section--intro & {
    margin-left: auto;
    margin-right: auto;
    @include bp($large-bp) {
      margin-right: 0;
    }
  }

  .c-section--outro & {
    margin-left: auto;
    margin-right: auto;
  }
}

.c-card--hero {
  padding: 0;
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;

  @include bp($large-bp) {
    padding: 0;
  }

  &:hover {
    transform: translateY(-0.5rem);
    box-shadow: -5px 5px 40px rgba(0, 0, 0, 0.1),
      -2px 9px 20px rgba(0, 0, 0, 0.04);

    @include bp($large-bp) {
      transform: translateY(-0.75rem);
    }
  }

  &:focus-visible {
    transform: translateY(-0.5rem);
    box-shadow: -22px 20px 60px rgba(0, 0, 0, 0.05), 4px -4px 0 #eb9178,
      -4px 4px 0 #eb9178, 4px 4px 0 #eb9178, -4px -4px 0 #eb9178;

    @include bp($large-bp) {
      transform: translateY(-0.75rem);
    }
  }
}

/* Wrapper
   ========================================================================== */

.c-card__wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 0.25rem;
}

/* Image
   ========================================================================== */

.c-card__image--default {
  width: 100%;
  max-width: 11.125rem;
  margin: 0 auto 3.5rem;
  margin-bottom: 1.25rem;

  @include bp($large-bp) {
    max-width: 14.1875rem;
    //margin-bottom: 5rem;

    margin-bottom: 2rem;
  }
}

.c-card__image--person {
  .c-card--landscape & {
    width: 6rem;
  }

  .c-card--landscape & {
    &::before {
      padding-bottom: 128 / 96 * 100%;
    }
  }

  .c-card--portrait & {
    &::before {
      padding-bottom: 113 / 162 * 100%;
    }
  }

  @include bp($large-bp) {
    .c-card--landscape & {
      width: 13.375rem;

      &::before {
        padding-bottom: 267.5 / 214 * 100%;
      }
    }

    .c-card--portrait & {
      &::before {
        padding-bottom: 188 / 282 * 100%;
      }
    }
  }
}

/* Header and Typo
   ========================================================================== */

.c-card__inner {
  padding: 0.75rem 0.75rem 2rem;

  .c-card--landscape & {
    padding: 0.75rem 0.75rem 1rem 1rem;
    width: calc(100% - 6rem);
  }

  @include bp($large-bp) {
    padding: 1.25rem 1.5rem 1.5rem;

    .c-card--landscape & {
      display: flex;
      flex-direction: column;
      width: calc(100% - 13.375rem);
    }
  }
}

.c-card__inner--default {
  padding: 0;
  //margin-top: auto;
}

.c-card__header {
  margin-bottom: 2rem;

  @include bp($large-bp) {
    margin-bottom: 2.5rem;
  }

  .c-card--person & {
    margin-bottom: 0;
  }
}

.c-card__inner--hero {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.5) 75%,
    rgba(0, 0, 0, 0.5) 100%
  );

  padding: {
    left: 1.25rem;
    right: 1.25rem;
    bottom: 1.5rem;
  }

  @include bp($large-bp) {
    padding: 2rem;
  }
}

.c-card__headline {
  @include text-token('sans', 'headline', 'xs');

  margin: 0;
  color: map-get($brand-colors, 'black', '900');

  .c-card--person & {
    @include text-token('sans', 'headline', '4xs');
  }

  .c-card--portrait & {
    @include text-token('sans', 'headline', '3xs');
  }

  @include bp($large-bp) {
    .c-card--person & {
      @include text-token('sans', 'headline', 'xs');
    }
  }
}

.c-card__headline--hero {
  color: map-get($brand-colors, 'white', '900');
  @include text-token('sans', 'headline', 'sm');
}

.c-card__description {
  color: map-get($brand-colors, 'black', '900');
  @include text-token('sans', 'copy', 'sm');

  margin-top: 0.5rem;

  @include bp($large-bp) {
    margin-top: 1rem;
  }
}

.c-card_headline--default {
  @include text-token('sans', 'headline', '3xs');

  color: map-get($brand-colors, 'black', '900');

  @include bp($large-bp) {
    @include text-token('sans', 'headline', '2xs');
  }
}

.c-card__subline {
  @include text-token('sans', 'copy', 'md');
}

.c-card__data {
  @include text-token('sans', 'copy', 'sm');
  margin-top: 1rem;

  .c-card--landscape & {
    @include text-token('sans', 'copy', 'xs');
  }

  @include bp($large-bp) {
    &,
    .c-card--landscape & {
      @include text-token('sans', 'copy', 'sm');
    }
  }
}

.c-card__flagline {
  @include text-token('sans', 'copy', 'sm');

  display: block;
  color: map-get($brand-colors, 'black', '900-50');

  .c-card--portrait.c-card--l & {
    @include text-token('sans', 'copy', 'xs');
  }

  @include bp($large-bp) {
    &,
    .c-card--portrait &,
    .c-card--landscape & {
      @include text-token('sans', 'copy', 'sm');
    }
  }
}

.c-flagline--default {
  @include text-token('sans', 'copy', 'sm');

  color: map-get($brand-colors, 'red', '500');

  @include bp($large-bp) {
    @include text-token('sans', 'copy', 'sm');
  }
}

.c-card__flagline--hero {
  color: map-get($brand-colors, 'white', '900');

  @include bp($large-bp) {
    margin-bottom: 0.25rem;
  }
}

.c-card__link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 2;
}
