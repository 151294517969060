.c-section {
  min-height: 100vh;
  width: 100%;
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: row;

  position: relative;
}

.c-section--split-right {
  flex-direction: row-reverse;
}

.c-section--full,
.c-section--facts,
.c-section--related,
.c-section--device {
  align-items: center;
}

/* Placement
   ========================================================================== */

.c-section__top,
.c-section__bottom {
  width: 100%;
}

.c-section__top {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
}

.c-section__bottom {
  background: map-get($brand-colors, 'white', '900');

  .c-section--intro-alt & {
    background: transparent;
  }
}

.c-section__full {
  width: 100%;
}

.c-section__left,
.c-section__right {
  width: 100%;
  flex-shrink: 0;

  @include bp($large-bp) {
    width: 60%;
  }
}

.c-section__left {
  display: flex;
  align-items: center;

  padding: 1.5rem;

  @include bp($large-bp) {
    padding: 4rem;
  }
}

.c-section__left {
  .c-section--outro & {
    @include bp($large-bp) {
      width: 40%;
      align-items: center;
    }
  }
}

.c-section__right {
  .c-section--outro & {
    padding: 1.5rem;
    overflow-y: auto;

    @include bp($large-bp) {
      width: 60%;
      max-width: 820px;
      padding: 4rem;
      height: auto;
      background-color: map-get($brand-colors, 'black', '800');
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
  }
}

.c-section__right {
  @include bp($large-bp) {
    width: 40%;
  }
}

.c-section__inner--image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

/* Inner
   ========================================================================== */

.c-section__inner {

  .c-section--intro & {
    &.c-section__top {
      padding-bottom: 2rem;
      text-align: center;

      @include bp($large-bp) {
        padding-bottom: 3rem;
        text-align: left;
      }

      @include bp($wide-bp) {
        padding-bottom: 4rem;
      }
    }
  }

  .c-section--intro-alt & {
    &.c-section__top {
      padding-bottom: 0.5rem;
      text-align: center;

      @include bp($large-bp) {
        padding-bottom: 1rem;
        text-align: left;
      }

      @include bp($wide-bp) {
        padding-bottom: 2rem;
      }
    }
  }

  .c-section--intro-alt &,
  .c-section--intro & {
    &.c-section__bottom {
      padding-top: 2rem;
      text-align: center;

      @include bp($large-bp) {
        padding-top: 3rem;
        text-align: left;
      }

      @include bp($wide-bp) {
        padding-top: 6rem;
      }
    }
  }

  .c-section--intro-alt & {
    &.c-section__bottom {
      padding-top: 0.5rem;
      text-align: center;

      @include bp($large-bp) {
        padding-top: 1rem;
        text-align: left;
      }

      @include bp($wide-bp) {
        padding-top: 2rem;
      }
    }
  }

  .c-section[data-horizontal="true"] & {
    overflow: hidden;
  }
}

/* Text
   ========================================================================== */

.c-section__headline {
  @include text-token('serif', 'headline', 'lg');

  .c-section--intro-alt &,
  .c-section--intro & {
    @include text-token('serif', 'headline', 'xl');
  }

  .c-section--intro-alt &,
  .c-section--full &,
  .c-section--outro &,
  .c-section--related &,
  .c-section--facts &,
  .c-section--intro &,
  .c-section--device &,
  .c-section--split-right &,
  .c-section--split-left & {
    color: map-get($brand-colors, 'white', '900');
  }

  .c-section--facts &,
  .c-section--related & {
    margin-bottom: 2rem;

    @include bp($large-bp) {
      margin-bottom: 4rem;
    }
  }

  .c-section--facts & {
    margin-bottom: 2rem;

    + .c-section__text {
      margin-top: -1rem;
      margin-bottom: 2rem;
    }

    @include bp($large-bp) {
      margin-bottom: 4rem;

      + .c-section__text {
        margin-top: -2rem;
        margin-bottom: 4rem;
      }
    }
  }
}

.c-section__text {
  @include text-token('sans', 'copy', 'lg');

  .c-section--full &,
  .c-section--facts &,
  .c-section--outro &,
  .c-section--device &,
  .c-section--split-right &,
  .c-section--split-left & {
    color: map-get($brand-colors, 'white', '900');
  }
}

/* Intro
   ========================================================================== */

.c-section__logo {
  width: 15.625rem;
  margin-bottom: 2rem;

  @include bp($large-bp) {
    width: 20rem;
  }

  @include bp($wide-bp) {
    width: 25rem;
  }

  .c-section--intro-alt & {
    color: map-get($brand-colors,'white', '900');
  }
}

.c-section__action {
  position: absolute;
  bottom: 1rem;
}

/* Horizontal Scroll
   ========================================================================== */
