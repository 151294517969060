/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 */

html {
  background: $color-bg;
  background: url('../images/background.jpg') fixed center no-repeat;
  background-size: cover;
  color: $text-color;
  height: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  font: normal 1rem/1.5 $text-font-stack;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background: map-get($brand-colors, 'black', '900-50');
  }
}
