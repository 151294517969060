/* ==========================================================================
   INPUT
   ========================================================================== */

.c-input {
  display: flex;
  flex-direction: column;
  position: relative;

  &.c-input--with-trigger {
    flex-direction: row;
  }

  + .c-input {
    margin-top: 1rem;
  }
}

.c-input--disabled {
  pointer-events: none;
}

.c-input__label {
  @include text-token('sans', 'copy', 'sm');
  margin-bottom: 0.25rem;
  color: map-get($brand-colors, 'white', '900');
}

.c-input__label__asterix {
  color: map-get($brand-colors, 'error', '900');
}

.c-input__label--text {
  @include text-token('sans', 'copy', 'lg');
  color: map-get($brand-colors, 'black', '900');

  @include bp($large-bp) {
    margin: 0 auto;

    &.c-input__label--text-left {
      margin: 0;
    }
  }
}

.c-input__label--divider {
  border-bottom: 1px solid map-get($brand-colors, 'black', '200');
  margin-top: 1.5rem !important;

  @include bp($large-bp) {
    margin-top: 3rem !important;
  }
}

.c-input__text {
  padding-top: 2rem;
}

.c-input__control {
  @include text-token('sans', 'copy', 'sm');

  width: 100%;
  padding: 0.625rem;
  border-radius: 0.25rem;
  border: 0.125rem solid map-get($brand-colors, 'black', '200');

  &::placeholder {
    color: map-get($brand-colors, 'black', '200');
  }

  &:focus {
    box-shadow: 4px -4px 0 #eb9178, -4px 4px 0 #eb9178, 4px 4px 0 #eb9178,
      -4px -4px 0 #eb9178;
  }

  &:active {
    background-color: map-get($brand-colors, 'warm-grey', '100');
    border-color: map-get($brand-colors, 'black', '800');
  }
  .c-input--disabled & {
    background-color: map-get($brand-colors, 'warm-grey', '100');
  }

  .c-input--success & {
    background-color: map-get($brand-colors, 'success', '100');
    border-color: map-get($brand-colors, 'success', '500');
  }

  .c-input--error & {
    background-color: map-get($brand-colors, 'error', '100');
    border-color: map-get($brand-colors, 'error', '900');
  }

  @include bp($large-bp) {
    @include text-token('sans', 'copy', 'lg');

    padding: 1rem 1.125rem;
  }
}

.c-input__validation {
  @include text-token('sans', 'copy', 'xs');

  color: map-get($brand-colors, 'green', '500');
}

.c-input__validation--error {
  color: map-get($brand-colors, 'red', '500');
}

.pristine-error,
.c-input__link {
  @include text-token('sans', 'copy', 'xs');

  margin-top: 0.25rem;

  @include bp($large-bp) {
    @include text-token('sans', 'copy', 'sm');
  }
}

.pristine-error {
  color: map-get($brand-colors, 'error', '900');
}

input[type='date'] {
  min-height: 2.6875rem;
  -webkit-appearance: none;
  background: map-get($brand-colors, 'white', '900');
}

input[type='time'] {
  /* The space between the fields - between hour and minute, the minute and second, second and am/pm */
  &::-webkit-datetime-edit-hour-field,
  &::-webkit-datetime-edit-minute-field,
  &::-webkit-datetime-edit-text {
    color: map-get($brand-colors, 'black', '900');
  }

  /* Up/Down arrows for incrementing/decrementing the value */
  &::-webkit-inner-spin-button {
    display: none;
  }

  &::-webkit-calendar-picker-indicator {
    //display: none;
  }
}
