/* ==========================================================================
   FACT CARD
   ========================================================================== */

.c-fact-card {
  justify-content: space-evenly;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.c-fact-card__item {
  padding: 0 0 3.90625rem;
  color: $color-white;
  text-align: center;

  .c-widget--theme-green-100 &,
  .c-widget--theme-red-100 &,
  .c-widget--theme-warm-grey-100 & {
    color: map-get($brand-colors, 'black', '900');
  }

  @include bp($large-bp) {
    text-align: left;
  }
}

.c-fact-card__item-label {
  @include text-token('sans', 'headline', '4xs');

  font-weight: 400;

  @include bp($large-bp) {
    @include text-token('sans', 'headline', '2xs');
  }
}

.c-fact-card__item-value {
  font: normal 60px/78px $serif-font-stack;
  letter-spacing: 0.006em;

  @include bp($large-bp) {
    top: 0;
    font-size: 90px;
    line-height: 117px;
  }
}

.c-fact-card__item-value--ghost {
  display: none;

  @include bp($large-bp) {
    position: relative;
    visibility: hidden;
    display: none;
  }
}

.c-fact-card__slider {
  margin-bottom: 2rem;

  @include bp($large-bp) {
    margin-bottom: 0;
  }
}

.c-fact-card__cta {
  margin: 0;
}
