@each $value, $i in $brand-colors {
  .u-color {
    @each $type, $color in $i {
      &-#{$value}--#{$type} {
        background-color: $color;
        height: 200px;
        width: 200px;
        margin: 20px;
        border: 2px solid #000000;
        display: flex;
        position: relative;

        > div {
          background-color: #ffffff;
          display: flex;
          flex-wrap: wrap;
          margin-top: auto;
          width: 100%;
          padding: 5px;
          font-weight: bold;

          &:after {
            width: 100%;
            content: '#{$color}';
            font-weight: normal;
          }
        }
      }
    }
  }
}
