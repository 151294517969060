/* ==========================================================================
   PADDING
   ========================================================================== */

@each $dir in 'top', 'bottom', 'left', 'right' {
  .is-paddingless--#{$dir} {
    padding-#{$dir}: 0;
  }
  @include bp-down($large-bp) {
    .is-paddingless--#{$dir}-mobile {
      padding-#{$dir}: 0;
    }
  }
  @include bp($large-bp) {
    .is-paddingless--#{$dir}-desktop {
      padding-#{$dir}: 0;
    }
  }
}
