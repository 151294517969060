/* ==========================================================================
   SPACING
   ========================================================================== */

@each $name, $value in $spacing {
  @each $dir in 'top', 'bottom' {
    .u-space--#{$name}-#{$dir} {
      @include fp(
        padding-#{$dir},
        map-get($value, 'min'),
        map-get($value, 'max')
      );
    }
    @include bp-down($large-bp) {
      .u-space--#{$name}-#{$dir}-mobile {
        @include fp(
          padding-#{$dir},
          map-get($value, 'min'),
          map-get($value, 'max')
        );
        //padding-#{$dir}: map-get($value, 'min');
      }
    }
  }
}
