/* ==========================================================================
   #PARAGRAPHS
   ========================================================================== */

/**
 * Simple default styles for paragraphs. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

p {
  @include fp(font-size, 14, 16);

  line-height: 1.4;

  &:not(:last-child) {
    @include fp(margin-bottom, 14, 16);
  }
}
