/* ==========================================================================
   LISTS
   ========================================================================== */

.c-list {
  list-style: none;
  margin: 0;
}

.c-list--definition {
  margin-top: 0.75rem;
  margin-bottom: 0;
}

.c-list__item {
  margin: 0;

  .c-list--large & {
    @include text-token('sans', 'copy', 'lg');
  }
}

.c-list--with-icons {
  padding: 0;
}

.c-list__item--with-icon {
  display: flex;
  align-items: center;
}

.c-list__item-icon {
  font-size: 1.125em;
  position: relative;
  top: 0.1375em;

  @include bp($large-bp) {
  }
}

.c-list__item-data {
  display: block;
  margin-left: 0.5rem;

  @include bp($large-bp) {
    margin-left: 0.75rem;
  }
}

a.c-list__item-data {
  text-decoration: none;
  @include text-token('sans', 'copy', 'md');
}

.c-list__group {
  display: flex;
}

.c-list__title {
  width: 17%;
  font-weight: normal;
}

.c-list__data {
  margin: 0;
}
