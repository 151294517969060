/* ==========================================================================
   #FONT-FACE
   ========================================================================== */

/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */

@font-face {
  font-family: 'Playfair Display';
  src: url('../fonts/playfair-display/playfair-display-v29-latin-800.woff2')
      format('woff2'),
    url('../fonts/playfair-display/playfair-display-v29-latin-800.woff')
      format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
