/* ==========================================================================
   ERROR Page
   ========================================================================== */

.o-error-page {
  text-align: center;
}

.o-error-page__headline {
  font: normal 500 60px / 1.125 $serif-font-stack;
  margin-bottom: 1rem;
}

.o-error-page__subline {
  font: normal 500 32px / 1.25 $serif-font-stack;
  margin-bottom: 0.5rem;
}

.o-error-page__text {
  .o-error-page & {
    margin-bottom: 3rem;
  }
}

.o-error-page__text--alt {
  .o-error-page & {
    margin-bottom: 2.5rem;
  }
}

.o-error-page__link-list {
  display: flex;
  flex-flow: column wrap;
  align-items: center;

  @include bp($large-bp) {
    flex-flow: row wrap;
    justify-content: center;
  }
}

.o-error-page__btn {
  &.c-btn {
    display: flex;
    justify-content: center;
    min-width: 14.5rem;
    margin-bottom: 0.5rem;
  }

  @include bp($large-bp) {
    &.c-btn {
      margin-right: 0.5rem;
    }
  }
}

.o-error-page__btn--main {
  &.c-btn {
    display: inline-flex;
    margin-bottom: 3.125rem;
  }
}
