///* ========================================================================
//   #GLOBAL
//   ======================================================================== */
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 3px !default;
$global-timing-func: cubic-bezier(0.7, 0.6, 0.1, 0.8);
$global-transition: all 300ms $global-timing-func !default;

/* Fonts
   ========================================================================== */

/// Regular font family
/// @type List
$text-font-stack: 'sofia-pro', frutiger, 'Frutiger Linotype', univers, calibri,
  'Gill Sans', 'Gill Sans MT', 'Myriad Pro', myriad, 'DejaVu Sans Condensed',
  'Liberation Sans', 'Nimbus Sans L', tahoma, geneva, 'Helvetica Neue',
  helvetica, arial, sans-serif !default;

/// Code (monospace) font family
/// @type List
$serif-font-stack: 'Playfair Display', constantia, 'Lucida Bright', lucidabright,
  'Lucida Serif', lucida, 'DejaVu Serif', 'Bitstream Vera Serif',
  'Liberation Serif', georgia, serif !default;

/* Breakpoints
   ========================================================================== */

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  small: 375px,
  medium: 768px,
  large: 1024px,
  xlarge: 1200px,
  wide: 1440px,
  xwide: 1920px,
) !default;

$mobile-bp: map-get($breakpoints, 'small');
$medium-bp: map-get($breakpoints, 'medium');
$large-bp: map-get($breakpoints, 'large');
$xlarge-bp: map-get($breakpoints, 'xlarge');
$wide-bp: map-get($breakpoints, 'wide');
$xwide-bp: map-get($breakpoints, 'xwide');

/// Container's maximum width
/// @type Length
$max-width: map-get($breakpoints, 'wide') !default;

/* Colors
   ========================================================================== */

$brand-colors: (
  'black': (
    '100': #e2e2e2,
    '200': #c9c9c9,
    '400': #979797,
    '800': #060021,
    '900': #000000,
    '900-50': rgba(#000000, 0.55),
    '900-25': rgba(#000000, 0.25),
  ),
  'white': (
    '900': #ffffff,
    '900-50': rgba(#ffffff, 0.55),
    '900-25': rgba(#ffffff, 0.25),
  ),
  'blue': (
    '700': #405fff,
    '900': #0029ff,
  ),
  'warm-grey': (
    '100': #f8f6f4,
    '100-85': rgba(#f8f6f4, 0.85),
    '200': #f0ede9,
    '300': #e9e4de,
    '400': #d9d2c8,
  ),
  'error': (
    '100': #fff0e6,
    '500': #ff4b32,
    '900': #b00200,
  ),
  'success': (
    '100': #f0faf5,
    '500': #00c3a0,
    '900': #1e4b50,
  ),
);

/* Type
   ========================================================================== */

$type: (
  'sans': (
    'headline': (
      'xs': (
        'font-family': 'sofia-pro',
        'font-weight': 500,
        'font-size': (
          'min': 18px,
          'max': 27px,
        ),
        'line-height': (
          'min': 1.4,
          'max': 1.4,
        ),
        'letter-spacing': -0.003em,
      ),
      '2xs': (
        'font-family': 'sofia-pro',
        'font-weight': 500,
        'font-size': (
          'min': 15px,
          'max': 21px,
        ),
        'line-height': (
          'min': 1.4,
          'max': 1.4,
        ),
        'letter-spacing': -0.003em,
      ),
      '3xs': (
        'font-family': 'sofia-pro',
        'font-weight': 500,
        'font-size': (
          'min': 18px,
          'max': 18px,
        ),
        'line-height': (
          'min': 1.4,
          'max': 1.4,
        ),
        'letter-spacing': -0.003em,
      ),
      '4xs': (
        'font-family': 'sofia-pro',
        'font-weight': 500,
        'font-size': (
          'min': 15px,
          'max': 15px,
        ),
        'line-height': (
          'min': 1.4,
          'max': 1.4,
        ),
        'letter-spacing': -0.003em,
      ),
      'sm': (
        'font-family': 'sofia-pro',
        'font-weight': 500,
        'font-size': (
          'min': 21px,
          'max': 36px,
        ),
        'line-height': (
          'min': 1.4,
          'max': 1.4,
        ),
        'letter-spacing': -0.003em,
      ),
      'md': (
        'font-family': 'sofia-pro',
        'font-weight': 500,
        'font-size': (
          'min': 27px,
          'max': 48px,
        ),
        'line-height': (
          'min': 1.4,
          'max': 1.4,
        ),
        'letter-spacing': -0.003em,
      ),
      'lg': (
        'font-family': 'sofia-pro',
        'font-weight': 500,
        'font-size': (
          'min': 27px,
          'max': 60px,
        ),
        'line-height': (
          'min': 1.4,
          'max': 1.3,
        ),
        'letter-spacing': -0.003em,
      ),
      'lg-alt': (
        'font-family': 'sofia-pro',
        'font-weight': 500,
        'font-size': (
          'min': 36px,
          'max': 60px,
        ),
        'line-height': (
          'min': 1.4,
          'max': 1.3,
        ),
        'letter-spacing': -0.003em,
      ),
    ),
    'copy': (
      '2xs': (
        'font-family': 'sofia-pro',
        'font-weight': 400,
        'font-size': (
          'min': 10px,
          'max': 10px,
        ),
        'line-height': (
          'min': 1.6,
          'max': 1.6,
        ),
        'letter-spacing': -0.003em,
      ),
      'xs': (
        'font-family': 'sofia-pro',
        'font-weight': 400,
        'font-size': (
          'min': 10px,
          'max': 12px,
        ),
        'line-height': (
          'min': 1.6,
          'max': 1.6,
        ),
        'letter-spacing': -0.003em,
      ),
      'sm': (
        'font-family': 'sofia-pro',
        'font-weight': 400,
        'font-size': (
          'min': 12px,
          'max': 15px,
        ),
        'line-height': (
          'min': 1.6,
          'max': 1.6,
        ),
        'letter-spacing': -0.003em,
      ),
      'md': (
        'font-family': 'sofia-pro',
        'font-weight': 400,
        'font-size': (
          'min': 15px,
          'max': 18px,
        ),
        'line-height': (
          'min': 1.6,
          'max': 1.6,
        ),
        'letter-spacing': -0.003em,
      ),
      'lg': (
        'font-family': 'sofia-pro',
        'font-weight': 400,
        'font-size': (
          'min': 15px,
          'max': 21px,
        ),
        'line-height': (
          'min': 1.6,
          'max': 1.6,
        ),
        'letter-spacing': -0.003em,
      ),
      'xs bold': (
        'font-family': 'sofia-pro',
        'font-weight': 500,
        'font-size': (
          'min': 10px,
          'max': 12px,
        ),
        'line-height': (
          'min': 1.6,
          'max': 1.6,
        ),
        'letter-spacing': -0.003em,
      ),
      'sm bold': (
        'font-family': 'sofia-pro',
        'font-weight': 500,
        'font-size': (
          'min': 12px,
          'max': 15px,
        ),
        'line-height': (
          'min': 1.6,
          'max': 1.6,
        ),
        'letter-spacing': -0.003em,
      ),
      'md bold': (
        'font-family': 'sofia-pro',
        'font-weight': 500,
        'font-size': (
          'min': 15px,
          'max': 18px,
        ),
        'line-height': (
          'min': 1.6,
          'max': 1.6,
        ),
        'letter-spacing': -0.003em,
      ),
      'lg bold': (
        'font-family': 'sofia-pro',
        'font-weight': 500,
        'font-size': (
          'min': 15px,
          'max': 21px,
        ),
        'line-height': (
          'min': 1.6,
          'max': 1.6,
        ),
        'letter-spacing': -0.003em,
      ),
    ),
    'copy-lead': (
      'md': (
        'font-family': 'sofia-pro',
        'font-weight': 500,
        'font-size': (
          'min': 15px,
          'max': 21px,
        ),
        'line-height': (
          'min': 1.6,
          'max': 1.6,
        ),
        'letter-spacing': -0.003em,
      ),
      'xl': (
        'font-family': 'sofia-pro',
        'font-weight': 400,
        'font-size': (
          'min': 18px,
          'max': 27px,
        ),
        'line-height': (
          'min': 1.5,
          'max': 1.5,
        ),
        'letter-spacing': -0.003em,
      ),
    ),
  ),
  'serif': (
    'headline': (
      '3xs': (
        'font-family': 'Playfair Display',
        'font-weight': 800,
        'font-size': (
          'min': 13.5px,
          'max': 16.2px,
        ),
        'line-height': (
          'min': 1.4,
          'max': 1.4,
        ),
        'letter-spacing': 0.006em,
      ),
      '4xs': (
        'font-family': 'Playfair Display',
        'font-weight': 800,
        'font-size': (
          'min': 13.5px,
          'max': 13.5px,
        ),
        'line-height': (
          'min': 1.4,
          'max': 1.4,
        ),
        'letter-spacing': 0.006em,
      ),
      'sm': (
        'font-family': 'Playfair Display',
        'font-weight': 800,
        'font-size': (
          'min': 32.4px,
          'max': 18.9px,
        ),
        'line-height': (
          'min': 1.5,
          'max': 1.5,
        ),
        'letter-spacing': 0.006em,
      ),
      'sm-alt': (
        'font-family': 'Playfair Display',
        'font-weight': 800,
        'font-size': (
          'min': 18.9px,
          'max': 32.4px,
        ),
        'line-height': (
          'min': 1.5,
          'max': 1.5,
        ),
        'letter-spacing': 0.006em,
      ),
      'md': (
        'font-family': 'Playfair Display',
        'font-weight': 800,
        'font-size': (
          'min': 24.3px,
          'max': 43.2px,
        ),
        'line-height': (
          'min': 1.4,
          'max': 1.3,
        ),
        'letter-spacing': 0.006em,
      ),
      'lg': (
        'font-family': 'Playfair Display',
        'font-weight': 800,
        'font-size': (
          'min': 24.3px,
          'max': 54px,
        ),
        'line-height': (
          'min': 1.4,
          'max': 1.3,
        ),
        'letter-spacing': 0.006em,
      ),
      'xl': (
        'font-family': 'Playfair Display',
        'font-weight': 800,
        'font-size': (
          'min': 38px,
          'max': 90px,
        ),
        'line-height': (
          'min': 1.4,
          'max': 1.3,
        ),
        'letter-spacing': 0.006em,
      ),
    ),
  ),
);

/* Spacing
   ========================================================================== */

$spacing: (
  'xl': (
    'min': 48px,
    'max': 96px,
  ),
  'l': (
    'min': 32px,
    'max': 64px,
  ),
  'm': (
    'min': 24px,
    'max': 48px,
  ),
  's': (
    'min': 16px,
    'max': 32px,
  ),
);

/// White
/// @type Color
$color-white: map-get($brand-colors, 'white', '900');

/// Focus
/// @type Color
$color-focus: rgba(#7b61ff, 0.25);

/// Background
/// @type Color
$color-bg: $color-white;

/// References
$text-color: map-get($brand-colors, 'black', '900');

/// Bulma settings

$tablet: $mobile-bp;
$desktop: $large-bp;
$widescreen: $xlarge-bp;
$column-gap: 12px;
