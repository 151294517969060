/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

.c-btn {
  @include anim();
  @include text-token('sans', 'copy', 'sm');

  font-weight: 500;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin: 0 0.75rem 0.75rem 0;
  cursor: pointer;
  padding: 0.625rem 1.375rem;
  border-radius: 0.25rem;
  text-decoration: none;
  color: map-get($brand-colors, 'white', '900');
  border: 1.5px solid transparent;

  &:focus-visible,
  &.is-focused {
    outline: none;
    box-shadow: -4px -4px 0 0 hsla(13, 74%, 70%, 1),
      4px 4px 0 0 hsla(13, 74%, 70%, 1), -4px 4px 0 0 hsla(13, 74%, 70%, 1),
      4px -4px 0 0 hsla(13, 74%, 70%, 1), 0 1px 2px 0 hsla(0, 0%, 0%, 0.06),
      0 1px 3px 0 hsla(0, 0%, 0%, 0.1);
  }

  &,
  &:active,
  &.is-active {
    background-color: map-get($brand-colors, 'black', '800');
    border-color: map-get($brand-colors, 'black', '800');
  }

  &:hover {
    background-color: lighten(map-get($brand-colors, 'black', '800'), 7.5);
    border-color: lighten(map-get($brand-colors, 'black', '800'), 7.5);
  }

  &[disabled],
  &.is-disabled {
    &,
    &:hover {
      background-color: map-get($brand-colors, 'warm-grey', '100');
      border-color: map-get($brand-colors, 'warm-grey', '100');
      color: map-get($brand-colors, 'black', '200');
      pointer-events: none;
    }
  }

  @include bp($large-bp) {
    @include text-token('sans', 'copy', 'md');
    margin: 0 1rem 1rem 0;
    border-width: 2px;

    padding: 0.875rem 1.875rem;
  }
}

.c-btn--alt {
  &,
  &:active,
  &.is-active {
    background-color: map-get($brand-colors, 'white', '900');
    border-color: map-get($brand-colors, 'white', '900');
    color: map-get($brand-colors, 'black', '800');
  }

  &:hover {
    background-color: lighten(map-get($brand-colors, 'black', '800'), 7.5);
    border-color: lighten(map-get($brand-colors, 'black', '800'), 7.5);
    color: map-get($brand-colors, 'white', '900');
  }
}

.c-btn--primary {
  &,
  &:active,
  &.is-active {
    background-color: map-get($brand-colors, 'blue', '900');
    border-color: map-get($brand-colors, 'blue', '900');
    color: map-get($brand-colors, 'white', '900');
  }

  &:hover {
    background-color: map-get($brand-colors, 'blue', '700');
    border-color: map-get($brand-colors, 'blue', '700');
    color: map-get($brand-colors, 'white', '900');
  }
}
