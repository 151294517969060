/* ==========================================================================
   SLIDER
   ========================================================================== */

@import 'node_modules/swiper/swiper';
@import 'node_modules/swiper/modules/scrollbar/scrollbar';
@import 'node_modules/swiper/modules/grid/grid';
@import 'node_modules/swiper/modules/pagination/pagination';
@import 'node_modules/swiper/modules/effect-fade/effect-fade';

.c-slider--text {
  height: 100vh !important;
}

.c-slider--text {
  transition: all 0.3s;
}
.swiper-slide.swiper-slide-next .c-slider--text,
.swiper-slide.swiper-slide-prev .c-slider--text {
  opacity: 0;
}
.swiper-slide.swiper-slide-active .c-slider--text {
  opacity: 1;
}

.c-slider__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
}

.swiper-container,
.swiper-slide {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  // for custom transition
  position: relative;
  overflow: hidden;
  display: flex;
}

.swiper-slide-inner {
  // for custom transition
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.c-slider__content {
  margin: auto;
}
