/* ==========================================================================
   #LISTS
   ========================================================================== */

ul {
  margin-left: 1em;
  padding-left: 1em;
}

li {
  margin-bottom: 0.5em;

  > ul,
  > ol {
    margin-bottom: 0;
    margin-left: 1em;
    margin-top: 0.5em;
  }
}

dt {
  font-weight: bold;
}

dd {
  margin-bottom: 1em;
  margin-left: 1em;
}
