/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

$headings: (
  'h1': (
    'type': 'headline',
    'token': 'md',
    'margin': 1rem,
  ),
  'h2': (
    'type': 'headline',
    'token': 'sm',
    'margin': 1rem,
  ),
  'h3': (
    'type': 'headline',
    'token': 'xs',
    'margin': 1rem,
  ),
  'h4': (
    'type': 'headline',
    'token': '2xs',
    'margin': 1rem,
  ),
  'h5': (
    'type': 'copy',
    'token': 'md',
    'margin': 0.5rem,
  ),
  'h6': (
    'type': 'copy',
    'token': 'sm',
    'margin': 0.5rem,
  ),
);

@each $level in map-keys($headings) {
  #{unquote($level)} {
    @if (map-has-key($headings, $level, 'sm')) {
      $type: map-get($headings, $level, 'sm', 'type');
      $token: map-get($headings, $level, 'sm', 'token');
      $margin: map-get($headings, $level, 'sm', 'margin');

      @include text-token('sans', $type, $token);

      margin-bottom: $margin;
      font-weight: 500;

      @include bp($large-bp) {
        $type: map-get($headings, $level, 'lg', 'type');
        $token: map-get($headings, $level, 'lg', 'token');
        $margin: map-get($headings, $level, 'lg', 'margin');

        @include text-token('sans', $type, $token);

        margin-bottom: $margin;
        font-weight: 500;
      }
    } @else {
      $type: map-get($headings, $level, 'type');
      $token: map-get($headings, $level, 'token');
      $margin: map-get($headings, $level, 'margin');

      @include text-token('sans', $type, $token);

      margin-bottom: $margin;
      font-weight: 500;
    }
  }
}
