/* ==========================================================================
   IMAGE
   ========================================================================== */

.c-img {
  position: relative;

  &::before {
    content: '';
    position: relative;
    display: block;
    padding-bottom: var(--image-aspect--mobile);

    @include bp($large-bp) {
      padding-bottom: var(--image-aspect);
    }
  }
}

.c-img__wrap {
  position: absolute;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  .c-img--loaded & {
    background: transparent;
  }
}

.c-img__full,
.c-img__placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .c-img--inline & {
    object-fit: cover;
    object-position: var(--image-focuspoint);
  }

  .c-img--bg & {
    background: transparent no-repeat var(--image-focuspoint) / cover;
  }
}

.c-img__placeholder {
  @include anim($prop: opacity);

  filter: blur(8px);
  transform: scale(1.125);

  [data-loaded='true'] + & {
    opacity: 0;
  }
}
