/* ==========================================================================
   FUNCTIONS
   ========================================================================== */

@function bp-val($name) {
  @if type-of($name) == 'number' {
    @return $name;
  }

  $val: map-get($breakpoints, $name);

  @if $val == null {
    @error 'Breakpoint #{$name} not found';
  }

  @return $val;
}

@function bp-val-next($name) {
  $breakpoint-names: map_keys($breakpoints);
  $n: index($breakpoint-names, $name);

  @if $n >= length($breakpoint-names) {
    @error 'Next breakpoint after #{$name} not found';
  }

  $next-key: nth($breakpoint-names, $n + 1);

  @return map-get($breakpoints, $next-key);
}

@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}
