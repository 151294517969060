/* ==========================================================================
   MIXINS
   ========================================================================== */

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

@mixin bp($name) {
  $val: bp-val($name);

  @media (min-width: $val) {
    @content;
  }
}

@mixin bp-down($name) {
  $val: bp-val($name);

  @media (max-width: $val - 0.02) {
    @content;
  }
}

@mixin bp-only($name) {
  @if type-of($name) == 'number' {
    @error '#{$name} should be breakpoint name';
  }

  $min: bp-val($name);
  $max: bp-val-next($name);

  @media (min-width: $min) and (max-width: $max - 0.02) {
    @content;
  }
}

@mixin bp-between($name1, $name2) {
  $min: bp-val($name1);
  $max: bp-val($name2);

  @media (min-width: $min) and (max-width: $max - 0.02) {
    @content;
  }
}

@mixin anim($prop: all, $dur: 0.3s, $mode: $global-timing-func) {
  transition: $prop $dur $mode;
}

@mixin ps($content: '', $pos: absolute) {
  content: $content;
  position: $pos;
}

@mixin text-token($token...) {
  $text-styles: map-get($type, $token...);

  @each $prop, $key in $text-styles {
    @if ($prop == 'font-size') {
      @include fp('font-size', map-get($key, 'min'), map-get($key, 'max'));
    } @else if ($prop == 'line-height') {
      line-height: map-get($key, 'min');
      @include bp($large-bp) {
        line-height: map-get($key, 'max');
      }
    } @else {
      #{unquote($prop)}: $key;
    }
  }
}

@mixin fp(
  $properties,
  $min-value,
  $max-value,
  $min-vw: 375px,
  $max-vw: 1440px
) {
  @each $property in $properties {
    #{$property}: $min-value;
  }

  @media screen and (min-width: $min-vw) {
    @each $property in $properties {
      #{$property}: calc(
        #{$min-value} +
          #{strip-unit($max-value - $min-value)} *
          (100vw - #{$min-vw}) /
          #{strip-unit($max-vw - $min-vw)}
      );
    }
  }

  @media screen and (min-width: $max-vw) {
    @each $property in $properties {
      #{$property}: $max-value;
    }
  }
}
